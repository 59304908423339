<template>
  <div class="tab-contanier">
    <div v-for="(item, $index) in typeItems" :key="$index" :class="['panel', active === $index ? 'active' : '']" @click.stop="handleClick(item, $index)">
      <svg-icon className="icon" :iconClass="item.icon" />
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'
export default {
  components: { SvgIcon },
  props: {
    typeItems: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
        active: 0
    }
  },
  methods: {
    handleClick(item, index) {
      this.active = index
      this.$emit('handleClick', {
        item,
        index
      })
    }
  }
}
</script>

<style lang="less" scoped>
.tab-contanier {
  width: 100%;
  height: 100%;
  .panel {
    display: flex;
    align-items: center;
    width: 160px;
    height: 50px;
    background: #F7F7F7;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    color: #999999;
    margin-bottom: 10px;
    cursor: pointer;
    .icon {
      margin-left: 40px;
      font-size: 24px;
    }
    span {
      width: 56px;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      margin-left: 5px;
      text-align: center;
    }
  }
  &:first-child {
    margin-top: 30px;
  }
  & + .panel {
    margin-top: 18px;
  }
  .active {
    position: relative;
    background: #2395F3;
    color: #FFFFFF;
    & ::after {
      content: '';
      position:absolute;
      top: 18px;
      right: -10px;
      border-left:10px solid #2395F3;
      border-top:8px solid transparent;
      border-bottom:8px solid transparent;
    }
  }
}
</style>
