var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tab-contanier" },
    _vm._l(_vm.typeItems, function (item, $index) {
      return _c(
        "div",
        {
          key: $index,
          class: ["panel", _vm.active === $index ? "active" : ""],
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleClick(item, $index)
            },
          },
        },
        [
          _c("svg-icon", {
            attrs: { className: "icon", iconClass: item.icon },
          }),
          _c("span", [_vm._v(_vm._s(item.name))]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }